<template>
  <div class="container-fluid bg-gris-claro" id="contacto">
    <div class="container">
      <div class="row pb-5">
        <div class="col-md-5">
          <div class="card">
            <h5>{{ $t("footer.contacto") }}</h5>
            <span>nutentar@nutentar.com</span>
            <span>Vicente Lopez 226 - Moron CP (1708)</span>
            <span>Buenos Aires - Argentina</span>
            <span>{{ $t("footer.tel") }}</span>
            <span>Whatsapp: +54 911 55 29 14 59</span>
          </div>
        </div>
        <div class="col-md-5 offset-md-1">
          <!-- <iframe src="https://us4.list-manage.com/contact-form?u=6ed6a472a2f6c156fb23fada3&form_id=9fbb22ddb06e3cf6ac2924ecc2f8359a" frameborder="0" scrolling="no"></iframe> -->
          <form @submit="sendMessage" @reset="resetForm">
            <div class="form-group">
              <label for="nombre">Nombre y Apellido</label>
              <input
                type="text"
                class="form-control"
                id="nombre"
                v-model="form.nombre"
                required
              />
            </div>
            <div class="form-group">
              <label for="nombre">Teléfono</label>
              <input
                type="phone"
                class="form-control"
                id="phone"
                v-model="form.telefono"
                required
              />
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="email"
                class="form-control"
                id="email"
                v-model="form.email"
                required
              />
            </div>
            <div class="form-group">
              <label for="comentarios">Comentarios</label>
              <textarea
                type="textarea"
                class="form-control"
                id="comentarios"
                v-model="form.msg"
                required
              />
            </div>
            <button type="reset" class="btn btn-muted me-3">Borrar</button>
            <button type="submit" class="btn btn-primary">Enviar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Contacto",
  data() {
    return {
      form: {
        nombre: null,
        telefono: null,
        email: null,
        msg: null
      }
    }
  },
  methods: {
    sendMessage(e) {
      e.preventDefault();

      const config = {
        responseType: 'text',
      };
      axios
        .post(
          'PHPMailer/send.php',
          {
            'email': this.form.email,
            'nombre': this.form.nombre,
            'telefono': this.form.telefono,
            'msgHTML': this.form.msg.replaceAll('\n', '<br/>'),
            'msgAlt': this.form.msg,
          },
          config,
        )
        .then(response => {
          console.log('success', response.data.message);
          this.$swal({
            icon: "success",
            title: "Su mensaje fue enviado con éxito",
            text: "Le contataremos a la brevedad",
            confirmButtonColor: "#55afe5",
            showConfirmButton: true,
            timer: 3000,
          })
          .then(()=> {
              this.sendConfirmed(this.form, config)
              this.resetForm()
            }
          );
        })
        .catch(error => {
          console.log(error.response);
          this.$swal({
            icon: "error",
            title: "No fue posible enviar su mensaje",
            text: "Por favor, redirija su menasaje a: contacto@nutentar.com",
            confirmButtonColor: "#55afe5",
            showConfirmButton: false,
            allowEscapeKey: true,
            allowOutsideClick: true,
            showCloseButton: true,
          });
        });
    },
    resetForm(){
      this.form = {
        nombre: null,
        telefono: null,
        email: null,
        msg: null
      }
    },
    sendConfirmed(form, config){
      axios
        .post(
          'PHPMailer/sendConfirmed.php',
          {
            'email': form.email,
            'nombre': form.nombre,
            'msgHTML': form.msg.replaceAll('\n', '<br/>'),
            'msgAlt': form.msg,
          },
          config,
        )
    }
  },
};
</script>