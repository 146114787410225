<template>
  <div class="container-fluid bg-gris-claro py-4" id="hangPack">
    <div class="container bg-white pb-4">
      <div class="row">
        <img
          src="@/assets/images/hang-pack/banner_HangPack.webp"
          class="img-fluid p-0"
        />
      </div>
      <div class="row">
        <div class="col-md-4 d-flex justify-content-center pt-5">
          <div class="flex-column">
            <div class="text-center">
              <img
                src="@/assets/images/hang-pack/Iconos-02.png"
                class="img-fluid h100"
              />
            </div>
            <div class="text-center pb-2">
              <h5>{{ $t("hangPack.beneficios_sanitarios.titulo") }}</h5>
            </div>
            <div
              class="border-top-celeste border-2 text-center pt-4 pb-4 m0auto txt-gris w300"
              v-html="$t('hangPack.beneficios_sanitarios.texto')"
            ></div>
          </div>
        </div>
        <div class="col-md-4 d-flex justify-content-center pt-5">
          <div class="flex-column">
            <div class="text-center">
              <img
                src="@/assets/images/hang-pack/Iconos-03.png"
                class="img-fluid h100"
              />
            </div>
            <div class="text-center pb-2">
              <h5>{{ $t("hangPack.beneficios_logisticos.titulo") }}</h5>
            </div>
            <div
              class="border-top-celeste border-2 text-center pt-4 pb-4 m0auto txt-gris w300"
              v-html="$t('hangPack.beneficios_logisticos.texto')"
            ></div>
          </div>
        </div>
        <div class="col-md-4 d-flex justify-content-center pt-5">
          <div class="flex-column">
            <div class="text-center">
              <img
                src="@/assets/images/hang-pack/Iconos-04.png"
                class="img-fluid h100"
              />
            </div>
            <div class="text-center pb-2">
              <h5>{{ $t("hangPack.beneficios_economicos.titulo") }}</h5>
            </div>
            <div
              class="border-top-celeste border-2 text-center pt-4 pb-4 m0auto txt-gris w300"
              v-html="$t('hangPack.beneficios_economicos.texto')"
            ></div>
          </div>
        </div>
      </div>
      <div class="row" style="padding: 1rem 5rem 0 4rem">
        <CardsRecomendados :productos="['hang-pack']" />
      </div>
    </div>
  </div>
</template>

<script>
import CardsRecomendados from "../components/CardsRecomendados.vue";
export default {
  name: "HangPack",
  components: { CardsRecomendados },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>